export default {
  'home.name': 'Dashboard',
  'home.title': 'Archives',
  'home.fileName': 'File name',
  'home.fullPath': 'Path',
  'home.contentType': 'File type',
  'home.createdAt': 'Date created',
  'home.confirm.title.delete': 'Delete the file',
  'home.confirm.content.delete': 'Do you agree to delete these files?',
  'home.create': 'Add new file',
  'home.action': 'Action',
  'home.memberSelect': 'Members participating',
  'home.memberSelect-placeholder': 'Select members participating',
  'home.accepts': 'Members participating',
  'home.upload': 'Upload file',
  'home.clubs': 'Club',
  'home.formAdd.code': 'Member code',
  'home.formAdd.enterpriseName': 'Club',
  'home.formAdd.emailAddress': 'Email',
  'home.formAdd.type': 'Member type',
  'home.formAdd.name': 'Member name',
  'home.desc': 'Type',
  'home.desc-pl': 'Enter type',
};
