export default {
  'activities.status.param':
    '{status,select, 1 {Chưa đến} 2 {Đang diễn ra} 3 {Đã hết thúc} other {Không xác định}}',
  'activities.name': 'Event',
  'activities.listAction': 'Event',
  'activities.add': 'Thêm event',
  'activities.buttonSubmit': 'Thêm',
  'activities.buttonEdit': 'Cập nhật',
  'activities.time': 'Thời gian',
  'activities.startDay': 'Từ ngày',
  'activities.endDay': 'Đến ngày',
  'activities.view': 'Chi tiết event',
  'activities.edit': 'Cập nhật event',
  'activities.btnViewMember': 'Danh sách Khách hàng',
  'activities.listMember': 'Danh sách Khách hàng',
  'activities.totalMember': 'Tổng số Khách hàng',
  'activities.totalMemberActive': 'Số lượng Khách hàng tham gia',
  'activities.totalMemberNotActive': 'Số lượng Khách hàng không tham gia',
  'activities.title': 'Tên event',
  'activities.timeAction': 'Thời gian event',
  'activities.content': 'Nội dung event',
  'activities.meritBadgeIds': 'Chuyên hiệu',
  'activities.score': 'Điểm',
  'activities.clubIds': 'Đối tượng được tham gia',
  'activities.validatorTitle': 'Tên event không được để trống',
  'activities.validatorContent': 'Nội dung event không được để trống',
  'activities.startTime': 'Thời gian bắt đầu',
  'activities.endTime': 'Thời gian kết thúc',
  'activities.creator': 'Người tạo',
  'activities.status': 'Trạng thái',
  'activities.totalMemberTable': 'Số người tham gia',
  'activities.allMember': 'Tất cả Liên đội',
  'activities.delete.title': 'Xóa event',
  'activities.delete.content': 'Bạn có muốn xóa event này?',
  'activities.non-delete.title': 'Không thể xóa',
  'activities.non-delete.content': 'Không thể xóa event đã diễn ra',
  'activities.validatorTime': 'Thời gian bắt đầu phải lớn hơn thời gian hiện tại',
  'actionChild.code': 'Mã Khách hàng',
  'actionChild.name': 'Tên Khách hàng',
  'actionChild.emailAddress': 'Email',
  'activities.grade': 'Khối',
  'activities.unionOfDistrict': 'Từ Quận đội',
  'activities.detachment': 'Từ Liên đội',
  'activities.createSource': 'Nguồn',
  'activities.tableChild.title': 'Tên event con',
  'activities.tableChild.meritBadgeIds': 'Chuyên hiệu',
  'activities.tableChild.score': 'Điểm',
  'activities.tableChild.startTime': 'Thời gian bắt đầu',
  'activities.tableChild.endTime': 'Thời gian kết thúc',
  'activities.tableChild.grades': 'Khối',
  'activities.tableChild.status': 'Trạng thái',
  'actionChild.status.param':
    '{status,select, 1 {Chưa xác nhận} 2 {Tham gia} 3 {Không tham gia} other {Không xác định}}',
  'actionChild.confirm': 'Trạng thái tham gia',
  'activities.workunits': 'Chi đội',
  'actionChild.confirmTime': 'Thời gian tham gia',
  'activities.list-member': 'Danh sách Khách hàng',
  'activities.scoreCollected': 'Số điểm nhận được',
  'activities.scoreDay': 'Ngày tích điểm',
  'laudatory.modalHistory': 'Lịch sử tích điểm',
  'activities.address': 'Địa điểm',
  'activities.attach': 'File đính kèm',
  'activities.upload.title': 'Chọn file đính kèm',
  ////////////// add for HCA
  'activities.action': 'Hành động',
  'activities.executiveCommittee': 'Ban chấp hành',
  'activities.memberUnit': 'Khách hàng doanh nghiệp',
  'activities.linked': 'Khách hàng liên kết',
  'activities.memberPersonal': 'Khách hàng cá nhân',
  'activities.partnerEnterprise': 'Đối tác doanh nghiệp',
  'activities.partnerPersonal': 'Đối tác cá nhân',
  'activities.member': 'Đối tượng',
};
