export default {
  'home.name': 'Dashboard',
  'home.title': 'Tài liệu',
  'home.fileName': 'Tên File',
  'home.fullPath': 'Đường dẫn',
  'home.contentType': 'Loại file',
  'home.createdAt': 'Ngày tạo',
  'home.confirm.title.delete': 'Xóa File',
  'home.confirm.content.delete': 'Bạn đồng ý xóa những file này ?',
  'home.create': 'Thêm mới file',
  'home.action': 'Hành Động',
  'home.memberSelect': 'Đối tượng',
  'home.memberSelect-placeholder': 'Chọn đối tượng',
  'home.accepts': 'Người nhận',
  'home.upload': 'Tải lên file',
  'home.clubs': 'Câu lạc bộ',
  'home.formAdd.code': 'Mã hội viên',
  'home.formAdd.enterpriseName': 'Câu lạc bộ',
  'home.formAdd.emailAddress': 'Email',
  'home.formAdd.type': 'Loại thành viên',
  'home.formAdd.name': 'Tên hội viên',
  'home.desc': 'Phân loại',
  'home.desc-pl': 'Nhập phân loại',
};
