export default {
  'activities.status.param':
    '{status,select, 1 {Not started} 2 {In progress} 3 {Ended} other {Undefined}}',
  'activities.name': 'Event',
  'activities.listAction': 'Event',
  'activities.add': 'Add event',
  'activities.buttonSubmit': 'Add',
  'activities.buttonEdit': 'Update',
  'activities.time': 'Time',
  'activities.startDay': 'From date',
  'activities.endDay': 'To date',
  'activities.view': 'View event details',
  'activities.edit': 'Update event',
  'activities.btnViewMember': 'Member list',
  'activities.listMember': 'Member list',
  'activities.totalMember': 'Total members',
  'activities.totalMemberActive': 'Number of active members',
  'activities.totalMemberNotActive': 'Number of inactive members',
  'activities.title': 'Event name',
  'activities.timeAction': 'Event time',
  'activities.content': 'Event content',
  'activities.meritBadgeIds': 'Merit badges',
  'activities.score': 'Score',
  'activities.clubIds': 'Members participating',
  'activities.validatorTitle': 'Event name cannot be empty',
  'activities.validatorContent': 'Event content cannot be empty',
  'activities.startTime': 'Start Time',
  'activities.endTime': 'End Time',
  'activities.creator': 'Creator',
  'activities.status': 'Status',
  'activities.totalMemberTable': 'Total members',
  'activities.allMember': 'All members',
  'activities.delete.title': 'Delete event',
  'activities.delete.content': 'Do you want to delete this event?',
  'activities.non-delete.title': 'Can not delete',
  'activities.non-delete.content': 'Can not delete event had started',
  'activities.validatorTime': 'Start time must greater than now',
  'actionChild.code': 'Member code',
  'actionChild.name': 'Member name',
  'actionChild.emailAddress': 'Email',
  'activities.grade': 'Grade',
  'activities.unionOfDistrict': 'Union district',
  'activities.detachment': 'Detachment',
  'activities.createSource': 'Create source',
  'activities.tableChild.title': 'Child event name',
  'activities.tableChild.meritBadgeIds': 'Merit',
  'activities.tableChild.score': 'Score',
  'activities.tableChild.startTime': 'Start time',
  'activities.tableChild.endTime': 'End time',
  'activities.tableChild.grades': 'Grade',
  'activities.tableChild.status': 'Status',
  'actionChild.status.param':
    '{status,select, 1 {Not confirm} 2 {Confirmed} 3 {Reject} other {Không xác định}}',
  'actionChild.confirm': 'Status confirm',
  'activities.workunits': 'Class',
  'actionChild.confirmTime': 'Confirm time',
  'activities.list-member': 'List of members',
  'activities.scoreCollected': 'Score',
  'activities.scoreDay': 'Date',
  'laudatory.modalHistory': 'Score history',
  'activities.address': 'Address',
  'activities.attach': 'Attach file',
  'activities.upload.title': 'Choose attach file',
  ////////////// add for HCA
  'activities.action': 'Action',
  'activities.executiveCommittee': 'Executive committee',
  'activities.memberUnit': 'Unit member',
  'activities.linked': 'Linked member',
  'activities.memberPersonal': 'Personal member',
  'activities.partnerEnterprise': 'Enterprise partner',
  'activities.partnerPersonal': 'Partner personal',
  'activities.member': 'Member',
};
