export default {
  // 'server.notFound': 'Không tồn tại',
  // 'server.networkError': 'Lỗi mạng',
  // 'Server.AccountEmail.NotExists': 'Thông tin đăng nhập không đúng',
  // 'Server.AddBeat.Success': 'Thêm thành công',
  // 'Server.DeleteBeat.Success': 'Xóa thành công',
  // 'Server.UpdateBeat.Success': 'Cập nhập thành công',
  // 'Mes.Users.Login.IncorrectNameOrPassword': 'Sai tên đăng nhập hoặc mật khẩu',
  // 'Mes.Users.Login.Successfully': 'Đăng nhập thành công',
  // 'Mes.Classes.Year.MustHave2Characters': 'Năm học phải có 2 kí tự',
  // 'Mes.Classes.ClassCode.MustHave2Characters': 'Mã lớp học phải có 2 kí tự',
  // 'Mes.Classes.Add.Successfully': 'Thêm lớp học thành công',
  // 'Mes.Questions.Update.Successfully': 'Cập nhật câu hỏi thành công',
  // 'Mes.Questions.Add.Successfully': 'Thêm câu hỏi thành công',
  // 'Mes.Users.UpdateSuccessfully': 'Cập nhật thông tin tài khoản thành công',
  // 'Mes.Users.Add.Successfully': 'Thêm tài khoản thành công',
  // 'Mes.Users.DeleteUsers.Successfully': 'Xóa thành công',
  // 'Mes.Researchs.Update.Successfully': 'Cập nhật bài nghiên cứu thành công',
  // 'Mes.Researchs.Add.Successfully': 'Thêm bài nghiên cứu thành công',
  // 'Mes.Researchs.Delete.Successfully': 'Xóa bài nghiên cứu thành công',
  // 'Mes.Career.Delete.Successfully': 'Xóa Ngành thành công',
  // 'Mes.User.EmailAddress.NotFound': 'Địa chỉ email không tồn tại',
  // 'Mes.User.SendMail.Successfully': 'Gửi email thành công',
  // 'Mes.User.CheckRecoveryToken.Successfully': 'Mã phục hồi hợp lệ',
  // 'Mes.User.RecoveryCode.NotFound': 'Mã phục hồi không tồn tại',
  // 'Mes.User.PleaseCheckEmail': 'Mã phục hồi đã được gửi vào email, bạn hãy kiểm tra email',
  // 'Mes.User.RecoveryCode.Expired': 'Mã phục hồi đã hết hạn',
  // 'Mes.User.ResetForgotPassword.Successfully': 'Thay đổi mật khẩu thành công',
  // 'Mes.User.SignIn.Successfully': 'Đăng nhập thành công',
  'Mes.Career.Id.Required': 'Nhập id ngành',
  'Mes.Career.NotFound': 'Chức vụ không tồn tại',
  'Mes.Career.Name.Required': 'Nhập tên ngành',
  'Mes.Career.Name.OverLength': 'Tên quá dài',
  'Mes.Career.Description.OverLength': 'Mô tả quá dài',
  'Mes.Career.Code.Required': 'Nhập mã ngành',
  'Mes.Career.Code.OverLength': 'Mã quá dài',
  'Mes.Career.Code.AlreadyExist': 'Mã đã tồn tại',
  'Mes.Career.Create.Successfully': 'Tạo thành công',
  'Mes.Career.Update.Successfully': 'Cập nhật thành công',
  'Mes.Career.Delete.Successfully': 'Xóa thành công',
  'Mes.Career.GetDetail.Successfully': 'Nhận chi tiết thành công',
  'Mes.Career.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.Certificate.Id.Required': 'Nhập id chứng chỉ',
  'Mes.Certificate.NotFound': 'Chứng nhận không tồn tại',
  'Mes.Certificate.Name.Required': 'Nhập tên chứng chỉ',
  'Mes.Certificate.Name.OverLength': 'Tên quá dài',
  'Mes.Certificate.Description.OverLength': 'Mô tả quá dài',
  'Mes.Certificate.Code.Required': 'Nhập mã chứng chỉ',
  'Mes.Certificate.Code.OverLength': 'Mã quá dài',
  'Mes.Certificate.Code.AlreadyExist': 'Mã đã tồn tại',
  'Mes.Certificate.Create.Successfully': 'Tạo thành công',
  'Mes.Certificate.Update.Successfully': 'Cập nhật thành công',
  'Mes.Certificate.Delete.Successfully': 'Xóa thành công',
  'Mes.Certificate.GetDetail.Successfully': 'Nhận chi tiết thành công',
  'Mes.Certificate.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.Organization.Id.Required': 'Nhập id tổ chức',
  'Mes.Organization.NotFound': 'Tổ chức không tồn tại',
  'Mes.Organization.Name.Required': 'Nhập tên tổ chức',
  'Mes.Organization.Name.OverLength': 'Tên quá dài',
  'Mes.Organization.Description.OverLength': 'Mô tả quá dài',
  'Mes.Organization.Code.Required': 'Nhập mã tổ chức',
  'Mes.Organization.Code.OverLength': 'Mã quá dài',
  'Mes.Organization.Code.AlreadyExist': 'Mã đã tồn tại',
  'Mes.Organization.Create.Successfully': 'Tạo thành công',
  'Mes.Organization.Update.Successfully': 'Cập nhật thành công',
  'Mes.Organization.Delete.Successfully': 'Xóa thành công',
  'Mes.Organization.GetDetail.Successfully': 'Nhận chi tiết thành công',
  'Mes.Organization.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.Rank.NotFound': 'Thứ hạng không tồn tại',
  'Mes.Rank.Name.Required': 'Nhập tên hạng',
  'Mes.Rank.Name.OverLength': 'Tên quá dài',
  'Mes.Rank.FutureFee.Required': 'Nhập hội phí tương lai',
  'Mes.Rank.ApplyFutureYear.Required': 'Nhập năm áp dụng',
  'Mes.Rank.ApplyFutureYear.InValid': 'Năm áp dụng không hợp lệ',
  'Mes.Rank.Description.OverLength': 'Mô tả quá dài',
  'Mes.Rank.Update.Successfully': 'Cập nhật thành công',
  'Mes.Rank.GetDetail.Successfully': 'Nhận chi tiết thành công',
  'Mes.Rank.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.Enterprise.Id.Required': 'Nhập id doanh nghiệp',
  'Mes.Enterprise.NotFound': 'Doanh nghiệp không tồn tại',
  'Mes.Enterprise.Name.Required': 'Nhập tên doanh nghiệp',
  'Mes.Enterprise.Name.OverLength': 'Tên quá dài',
  'Mes.Enterprise.Description.OverLength': 'Mô tả quá dài',
  'Mes.Enterprise.Code.Required': 'Nhập mã doanh nghiệp',
  'Mes.Enterprise.Code.OverLength': 'Mã quá dài',
  'Mes.Enterprise.Code.AlreadyExist': 'Mã đã tồn tại',
  'Mes.Enterprise.Create.Successfully': 'Tạo thành công',
  'Mes.Enterprise.Update.Successfully': 'Cập nhật thành công',
  'Mes.Enterprise.Delete.Successfully': 'Xóa thành công',
  'Mes.Enterprise.GetDetail.Successfully': 'Nhận chi tiết thành công',
  'Mes.Enterprise.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.Market.Id.Required': 'Nhập id thị trường',
  'Mes.Market.NotFound': 'Thị trường không tồn tại',
  'Mes.Market.Name.Required': 'Nhập tên thị trường',
  'Mes.Market.Name.OverLength': 'Tên quá dài',
  'Mes.Market.Description.OverLength': 'Mô tả quá dài',
  'Mes.Market.Code.Required': 'Nhập mã thị trường',
  'Mes.Market.Code.OverLength': 'Mã quá dài',
  'Mes.Market.Code.AlreadyExist': 'Mã đã tồn tại',
  'Mes.Market.Create.Successfully': 'Tạo thành công',
  'Mes.Market.Update.Successfully': 'Cập nhật thành công',
  'Mes.Market.Delete.Successfully': 'Xóa thành công',
  'Mes.Market.GetDetail.Successfully': 'Nhận chi tiết thành công',
  'Mes.Market.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.TargetCustomer.Id.Required': 'Nhập id khách hàng mục tiêu',
  'Mes.TargetCustomer.NotFound': 'Khách hàng mục tiêu không tồn tại',
  'Mes.TargetCustomer.Name.Required': 'Nhập tên khách hàng mục tiêu',
  'Mes.TargetCustomer.Name.OverLength': 'Tên quá dài',
  'Mes.TargetCustomer.Description.OverLength': 'Mô tả quá dài',
  'Mes.TargetCustomer.Code.Required': 'Nhập mã khách hàng mục tiêu',
  'Mes.TargetCustomer.Code.OverLength': 'Mã quá dài',
  'Mes.TargetCustomer.Code.AlreadyExist': 'Mã đã tồn tại',
  'Mes.TargetCustomer.Create.Successfully': 'Tạo thành công',
  'Mes.TargetCustomer.Update.Successfully': 'Cập nhật thành công',
  'Mes.TargetCustomer.Delete.Successfully': 'Xóa thành công',
  'Mes.TargetCustomer.GetDetail.Successfully': 'Nhận chi tiết thành công',
  'Mes.TargetCustomer.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.Field.Id.Required': 'Nhập id lĩnh vực',
  'Mes.Field.NotFound': 'Lĩnh vự không tồn tại',
  'Mes.Field.Name.Required': 'Nhập tên lĩnh vực',
  'Mes.Field.Name.OverLength': 'Tên quá dài',
  'Mes.Field.Description.OverLength': 'Mô tả quá dài',
  'Mes.Field.Code.Required': 'Nhập mã lĩnh vực',
  'Mes.Field.Code.OverLength': 'Mã quá dài',
  'Mes.Field.Code.AlreadyExist': 'Mã đã tồn tại',
  'Mes.CareerId.NotFound': 'Id ngành không tồn tại',
  'Mes.Field.CareerId.Required': 'Nhập id ngành lĩnh vực',
  'Mes.Field.Create.Successfully': 'Tạo thành công',
  'Mes.Field.Update.Successfully': 'Cập nhật thành công',
  'Mes.Field.Delete.Successfully': 'Xóa thành công',
  'Mes.Field.GetDetail.Successfully': 'Nhận chi tiết thành công',
  'Mes.Field.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.FieldRelation.Id.Required': 'Nhập id lĩnh vực quan hệ',
  'Mes.FieldRelation.NotFound': 'Lĩnh vực quan hệ không toàn tại',
  'Mes.FieldRelation.Name.Required': 'Nhập tên lĩnh vực quan hệ',
  'Mes.FieldRelation.Name.OverLength': 'Tên quá dài',
  'Mes.FieldRelation.Description.OverLength': 'Mô tả quá dài',
  'Mes.Media.Id.Required': 'Nhập id media',
  'Mes.Media.NotFound': 'Media không tồn tại',
  'Mes.Media.File.Required': 'Nhập file media',
  'Mes.Media.Files.Required': 'Nhập các tập tin media',
  'Mes.Media.Create.Successfully': 'Tạo thành công',
  'Mes.Media.Delete.Successfully': 'Xóa thành công',
  'Mes.Media.GetDetail.Successfully': 'Nhận chi tiết thành công',
  'Mes.Media.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.Media.File.InValid': 'File không hợp lệ',
  'Mes.RefreshToken.Token.InValid': 'Token không hợp lệ',
  'Mes.RefreshToken.Token.Expired': 'Token đã hết hạn',
  'Mes.RefreshToken.Token.NotFound': 'Token không tồn tại',
  'Mes.User.PleaseCheckEmail': 'Kiểm tra email',
  'Mes.User.Id.Required': 'Nhập id người dùng',
  'Mes.User.NotFound': 'Người dùng không tồn tại',
  'Mes.User.IsLocked': 'Người dùng bị khóa',
  'Mes.User.PhoneNumber.Required': 'Nhập số điện thoại người dùng',
  'Mes.User.PhoneNumber.InValid': 'Số điện thoại người dùng không hợp lệ',
  'Mes.User.EmailAddress.Required': 'Nhập id người dùng',
  'Mes.User.EmailAddress.InValid': 'Nhập địa chỉ email người dùng',
  'Mes.User.EmailAddress.NotFound': 'Địa chỉ email không tồn tại',
  'Mes.User.Content.Required': 'Nhập nội dung người dùng',
  'Mes.User.Content.OverLength': 'Nội dung người dùng quá dài',
  'Mes.User.Name.Required': 'Nhập tên người dùng',
  'Mes.User.Name.OverLength': 'Tên người dùng quá dài',
  'Mes.User.Avatar.InValid': 'Ảnh đại diện người dùng không hợp lệ',
  'Mes.User.OperationStatus.Required': 'Nhập tình trạng hoạt động của người dùng',
  'Mes.User.Password.InValid': 'Mật khẩu không hợp lệ',
  'Mes.User.Password.NotEnoughLength': 'Mật khẩu không đủ độ dài',
  'Mes.User.DayOfBirth.Required': 'Nhập sinh nhật người dùng',
  'Mes.User.RecoveryCode.Expired': 'Nhập mã phục hồi',
  'Mes.User.RecoveryCode.NotFound': 'Mã phục hồi không tồn tại',
  'Mes.User.Create.Successfully': 'Tạo thành công',
  'Mes.User.Update.Successfully': 'Cập nhật thành công',
  'Mes.User.Delete.Successfully': 'Xóa thành công',
  'Mes.User.GetDetail.Successfully': 'Nhận chi tiết thành công',
  'Mes.User.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.User.Profile.Successfully': 'Hồ sơ thành công',
  'Mes.User.SignIn.Successfully': 'Đăng nhập thành công',
  'Mes.User.Refresh.Successfully': 'Làm mới thành công',
  'Mes.User.Signout.Successfully': 'Đăng xuất thành công',
  'Mes.User.SendMail.Successfully': 'Gửi email thành công',
  'Mes.User.SendMail.Failed': 'Gửi email thất bại',
  'Mes.User.ResetForgotPassword.Successfully': 'Đặt lại mật khẩu thành công',
  'Mes.User.CheckRecoveryToken.Successfully': 'Kiểm tra mã thông báo khôi phục thành công',
  'Mes.User.UpdateProfile.Successfully': 'Cập nhật thành công',
  'Mes.User.Username.NotFound': 'Tên đăng nhập không đúng',

  'Mes.Role.Id.Required': 'Nhập id vai trò',
  'Mes.Role.NotFound': 'Vai trò không tồn tại',
  'Mes.Role.Name.Required': 'Nhập tên vai trò',
  'Mes.Role.Name.OverLength': 'Tên vai trò quá dài',
  'Mes.Role.Create.Successfully': 'Tạo vai trò thành công',
  'Mes.Role.Update.Successfully': 'Cập nhật vai trò thành công',
  'Mes.Role.Delete.Successfully': 'Xóa vai trò thành công',
  'Mes.Role.GetDetail.Successfully': 'Nhận chi tiết vai trò thành công',
  'Mes.Role.Search.Successfully': 'Tìm kiếm vai trò thành công',

  'Mes.Permission.Code.Required': 'Thêm quyền',
  'Mes.Permission.Code.NotFound': 'Mã không tồn tại',
  'Mes.Permission.NotFound': 'Không tồn tại',
  'Mes.Permission.GetAll.Successfully': 'Nhận tất cả thành công',

  'Mes.Fee.Search.Successfully': 'Tìm kiếm hội phí thành công',
  'Mes.FeeHistory.Search.Successfully': 'Tìm kiếm lịch sử hội phí thành công',
  'Mes.Sponsor.Search.Successfully': 'Tìm kiếm doanh thu thành công',

  'Mes.LeaderInfo.EmailAddress.Required': 'Nhập địa chỉ email thông tin lãnh đạo',
  'Mes.LeaderInfo.EmailAddress.InValid': 'Email không hợp lệ',
  'Mes.LeaderInfo.PhoneNumber.Required': 'Nhập số điện thoại thông tin lãnh đạo',
  'Mes.LeaderInfo.PhoneNumber.InValid': 'Số điện thoại không hợp lệ',
  'Mes.LeaderInfo.Name.Required': 'Nhập tên thông tin lãnh đạo',
  'Mes.LeaderInfo.Name.OverLength': 'Tên quá dài',
  'Mes.LeaderInfo.Position.Required': 'Nhập chức vụ thông tin lãnh đạo',
  'Mes.LeaderInfo.Position.OverLength': 'Chức vụ quá dài',
  'Mes.LeaderInfo.Gender.Required': 'Nhập giới tính thông tin lãnh đạo',
  'Mes.LeaderInfo.Gender.InValid': 'Giới tính không hợp lệ',
  'Mes.ConnectInfo.EmailAddress.Required': 'Nhập email phụ trách kết nối',
  'Mes.ConnectInfo.EmailAddress.InValid': 'Email không hợp lệ',
  'Mes.ConnectInfo.PhoneNumber.Required': 'Nhập số điện thoại phụ trách kết nối',
  'Mes.ConnectInfo.PhoneNumber.InValid': 'Số điện thoại không hợp lệ',
  'Mes.ConnectInfo.Name.Required': 'Nhập tên phụ trách kết nối',
  'Mes.ConnectInfo.Name.OverLength': 'Tên quá dài',
  'Mes.ConnectInfo.Position.Required': 'Nhập chức vụ phụ trách kết nối',
  'Mes.ConnectInfo.Position.OverLength': 'Chức vụ quá dài',
  'Mes.ConnectInfo.Gender.Required': 'Nhập giới tính phụ trách kết nối',
  'Mes.ConnectInfo.Gender.InValid': 'Giới tính không hợp lệ',
  'Mes.Member.Id.Required': 'Yêu cầu nhập id thành viên',
  'Mes.Member.NotFound': 'Thành viên không tồn tại',
  'Mes.Member.Name.Required': 'Nhập tên thành viên',
  'Mes.Member.Name.OverLength': 'Tên quá dài',
  'Mes.Member.EnterpriseName.Required': 'Nhập tên doanh nghiệp',
  'Mes.Member.EnterpriseName.OverLength': 'Tên doanh nghiệp quá dài',
  'Mes.Member.EnterpriseEnglishName.OverLength': 'Tên tiếng anh doanh nghiệp quá dài',
  'Mes.Member.EnterpriseAcronymsName.OverLength': 'Tên viết tắt của doanh nghiệp quá dài',
  'Mes.Member.Represent.Required': 'Nhập đại diện',
  'Mes.Member.Represent.OverLength': 'Đại diện quá dài',
  'Mes.Member.TransactionOfficeAddress.Required': 'Nhập địa chỉ văn phòng giao dịch',
  'Mes.Member.TransactionOfficeAddress.OverLength': 'Địa chỉ văn phòng giao dịch quá dài',
  'Mes.Member.TaxCodeAddress.Required': 'Nhập mã số thuế',
  'Mes.Member.TaxCodeAddress.OverLength': 'Mã số quá dài',
  'Mes.Member.Website.OverLength': 'Website quá dài',
  'Mes.Member.Fanpage.OverLength': 'Fanpage quá dài',
  'Mes.Member.FieldRelationIds.Duplicate': 'Id trùng lặp',
  'Mes.Member.FieldRelationIds.Required': 'Nhập id',
  'Mes.Member.MarketId.Required': 'Nhập id thị trường',
  'Mes.Member.TargetCustomerId.Required': 'Nhập id khách hàng mục tiêu',
  'Mes.Member.CertificateId.Required': 'Nhập id chứng chỉ',
  'Mes.Member.EnterpriseId.Required': 'Nhập id doanh nghiệp',
  'Mes.Member.OrganizationId.Required': 'Nhập id tổ chức',
  'Mes.Member.LeaderInfo.Required': 'Nhập thông tin lãnh đạo',
  'Mes.Member.ConnectInfos.Required': 'Nhập thông tin liên lạc',
  'Mes.Member.IdCard.Required': 'Nhập id card thành viên',
  'Mes.Member.IdCard.OverLength': 'Id card quá dài',
  'Mes.Member.WorkUnit.Required': 'Nhập đơn vị công việc',
  'Mes.Member.WorkUnit.OverLength': 'Đơn vị công việc quá dài',
  'Mes.Member.Position.Required': 'Nhập chức vụ',
  'Mes.Member.Position.OverLength': 'Chức vụ quá dài',
  'Mes.Member.DayOfBirth.Required': 'Nhập ngày sinh nhật',
  'Mes.Member.Gender.Required': 'Nhập giới tính',
  'Mes.Member.Gender.InValid': 'Giới tính không hợp lệ',
  'Mes.Member.Avatar.InValid': 'Ảnh đại diện không hợp lệ',
  'Mes.Member.PartnerSource.Required': 'Nhập đối tác',
  'Mes.Member.PartnerSource.InValid': 'Đối tác không hợp lệ',
  'Mes.Member.EmailAddress.Required': 'Nhập địa chỉ email',
  'Mes.Member.EmailAddress.InValid': 'Địa chỉ email không hợp lệ',
  'Mes.Member.PhoneNumber.Required': 'Nhập số điện thoại',
  'Mes.Member.PhoneNumber.InValid': 'Số điện thoại không hợp lệ',
  'Mes.Member.EnterpriseScale.Required': 'Nhập quy mô doanh nghiệp',
  'Mes.Member.EnterpriseScale.InValid': 'Quy mô doanh nghiệp không hợp lệ',
  'Mes.Member.Create.Successfully': 'Tạo thành công',
  'Mes.Member.Update.Successfully': 'Cập nhật thành công',
  'Mes.Member.Delete.Successfully': 'Xóa thành công',
  'Mes.Member.GetDetail.Successfully': 'Nhận chi tiết thành công',
  'Mes.Member.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.Member.Create.Failed': 'Tạo thất bại',
  'Mes.Member.Update.Failed': 'Cập nhật thất bại',
  'Mes.Sponsor.Id.Required': 'Vui lòng nhập id doanh thu',
  'Mes.Sponsor.NotFound': 'Doanh thu không tồn tại',
  'Mes.Sponsor.Product.Required': 'Vui lòng nhập sản phẩm doanh thu',
  'Mes.Sponsor.Product.OverLength': 'Sản phẩm doanh thu quá dài',
  'Mes.Sponsor.Content.Required': 'Vui lòng nhập nội dung doanh thu',
  'Mes.Sponsor.Content.OverLength': 'Nội dung doanh thu quá dài',
  'Mes.Sponsor.Unit.Required': 'Vui lòng nhập đơn vị doanh thu',
  'Mes.Sponsor.Unit.OverLength': 'Đơn vị doanh thu quá dài',
  'Mes.Sponsor.UnitPrice.Required': 'Vui lòng nhập đơn giá doanh thu',
  'Mes.Sponsor.Quantity.Required': 'Vui lòng nhập số lượng sản phẩm doanh thu',
  'Mes.Sponsor.SponsorDate.Required': 'Vui lòng nhập ngày doanh thu',
  'Mes.Sponsor.Create.Successfully': 'Tạo doanh thu thành công',
  'Mes.Sponsor.Update.Successfully': 'Cập nhật doanh thu thành công',
  'Mes.Sponsor.Delete.Successfully': 'Xóa doanh thu thành công',
  'Mes.Sponsor.GetDetail.Successfully': 'Xem chi tiết doanh thu thành công',
  'Mes.NetRevenue.Id.Required': 'Vui lòng nhập id doanh thu',
  'Mes.NetRevenue.NotFound': 'Doanh thu không tồn tại',
  'Mes.NetRevenue.Product.Required': 'Vui lòng nhập sản phẩm doanh thu',
  'Mes.NetRevenue.Product.OverLength': 'Sản phẩm doanh thu quá dài',
  'Mes.NetRevenue.Content.Required': 'Vui lòng nhập nội dung doanh thu',
  'Mes.NetRevenue.Content.OverLength': 'Nội dung doanh thu quá dài',
  'Mes.NetRevenue.Unit.Required': 'Vui lòng nhập đơn vị doanh thu',
  'Mes.NetRevenue.Unit.OverLength': 'Đơn vị doanh thu quá dài',
  'Mes.NetRevenue.UnitPrice.Required': 'Vui lòng nhập đơn giá doanh thu',
  'Mes.NetRevenue.Quantity.Required': 'Vui lòng nhập số lượng sản phẩm doanh thu',
  'Mes.NetRevenue.NetRevenueDate.Required': 'Vui lòng nhập ngày doanh thu',
  'Mes.NetRevenue.Create.Successfully': 'Tạo doanh thu thành công',
  'Mes.NetRevenue.Update.Successfully': 'Cập nhật doanh thu thành công',
  'Mes.NetRevenue.Delete.Successfully': 'Xóa doanh thu thành công',
  'Mes.NetRevenue.GetDetail.Successfully': 'Xem chi tiết doanh thu thành công',
  'Mes.Notification.Id.Required': 'Chưa thêm người nhận',
  'Mes.Notification.NotFound': 'Không thể xóa thông báo đã gửi',
  'Mes.Notification.Title.Required': 'Tiêu đề không hợp lệ',
  'Mes.Notification.Title.OverLength': 'Tiêu đề quá dài',
  'Mes.Notification.Content.Required': 'Nội dung không hợp lệ',
  'Mes.Notification.Content.OverLength': 'Nội dung quá dài',
  'Mes.Notification.Method.Required': 'Hình thức không hợp lệ',
  'Mes.Notification.Method.InValid': 'Hình thức không hợp lệ',
  'Mes.Notification.Type.Required': 'Không hợp lệ',
  'Mes.Notification.Type.InValid': 'Không hợp lệ',
  'Mes.Notification.SendTime.Required': 'Thời gian gửi không hợp lệ',
  'Mes.Notification.SendTime.InValid': 'Thời gian gửi không hợp lệ',
  'Mes.Notification.MemberIds.Duplicate': 'Người nhận bị trùng',
  'Mes.Notification.MemberIds.Required': 'Người nhận không hợp lệ',
  'Mes.Notification.OtherEmails.Duplicate': 'Email bị trùng',
  'Mes.Notification.Create.Successfully': 'Thêm thông báo thành công',
  'Mes.Notification.Update.Successfully': 'Cập nhật thông báo thành công',
  'Mes.Notification.Update.Failed': 'Cập nhật thông báo thất bại',
  'Mes.Notification.Delete.Successfully': 'Xóa thông báo thành công',
  'Mes.Notification.GetDetail.Successfully': 'Chi tiết thông báo',
  'Mes.Notification.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.OtherEmail.Name.Required': 'Chưa thêm người ngoài hệ thống',
  'Mes.OtherEmail.EmailAddress.Required': 'Chưa thêm người ngoài hệ thống',
  'Failed to read the request form. Request body too large. The max request body size is 30000000 bytes.':
    'Tổng dung lượng tệp đính kèm không được quá 100 Mb',
  'Mes.Schedule.NotFound': 'Id không hợp lệ',
  'Mes.Schedule.Id.Required': 'Không tồn tại',
  'Mes.Schedule.MeetingHostName.Required': 'Tên người chủ trì không hợp lệ',
  'Mes.Schedule.MeetingHostName.OverLength': 'Tên người chủ trì quá dài',
  'Mes.Schedule.Title.Required': 'Tiêu đề không hợp lệ',
  'Mes.Schedule.Title.OverLength': 'Tiêu đề quá dài',
  'Mes.Schedule.Content.Required': 'Nội dung không hợp lệ',
  'Mes.Schedule.Content.OverLength': 'Nội dung quá dài',
  'Mes.Schedule.Address.Required': 'Địa chỉ không hợp lệ',
  'Mes.Schedule.Address.OverLength': 'Địa chỉ quá dài',
  'Mes.Schedule.Type.Required': 'Không hợp lệ',
  'Mes.Schedule.Type.InValid': 'Không hợp lệ',
  'Mes.Schedule.StartTime.Required': 'Thời gian bắt đầu không hợp lệ',
  'Mes.Schedule.EndTime.Required': 'Thời gian kết thúc không hợp lệ',
  'Mes.Schedule.EndTime.InValid': 'Thời gian kết thúc không hợp lệ',
  'Mes.Schedule.MemberIds.Duplicate': 'Người nhận bị trùng',
  'Mes.Schedule.MemberIds.Required': 'Người nhận không hợp lệ',
  'Mes.Schedule.OtherEmails.Duplicate': 'Email bị trùng',
  'Mes.Schedule.Create.Successfully': 'Thêm lịch thành công',
  'Mes.Schedule.Create.Failed': 'Thêm lịch thất bại',
  'Mes.Schedule.Update.Successfully': 'Cập nhật lịch thành công',
  'Mes.Schedule.Update.Failed': 'Cập nhật lịch thất bại',
  'Mes.Schedule.Delete.Successfully': 'Hủy lịch thành công',
  'Mes.Schedule.GetDetail.Successfully': 'Chi tiết lịch',
  'Mes.Schedule.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.Schedule.Cancel.Successfully': 'Hủy thành công',
  'Mes.User.NotDeletePermission': 'Không thể xóa người dùng này',
  'Mes.Role.NotDeletePermission': 'Không thể xóa Quyền này',
  'Mes.Member.Invite.Successfully': 'Mời thành viên thành công',
  'Mes.Member.Id.InValid': 'ID người dùng không hợp lệ',
  'Mes.FeeHistory.Year.InValid': 'Năm đóng hội phí không hợp lệ',
  'Mes.FeeHistory.Pending': 'Chưa hoàn thành hội phí',
  'Mes.Member.FeeHistories.InValid': 'Thành viên đóng phí không hợp lệ',
  'Mes.Fee.Create.Successfully': 'Đóng hội phí thành công',

  'Mes.Member.Code.AlreadyExist': 'Mã đăng nhập đã tồn tại',
  'Mes.AppUser.EmailAddress.AlreadyExist': 'Email đã tồn tại',
  'Mes.ClubRepresent.Code.AlreadyExist': 'Mã đăng nhập đã tồn tại',
  'Mes.ClubRepresent.Create.Successfully': 'Thêm ban điều hành thành công',
  'Mes.ClubRepresent.Update.Successfully': 'Chỉnh sửa thành công',
  'Mes.ClubRepresent.Delete.Successfully': 'Xóa ban điều hành thành công',

  'Mes.Career.IsUsing': 'Ngành đang được sử dụng',
  'Mes.Certificate.IsUsing': 'Chứng chỉ đang được sử dụng',
  'Mes.Enterprise.IsUsing': 'Doanh nghiệp đang được sử dụng',
  'Mes.Field.IsUsing': 'Lĩnh vực đang được sử dụng',
  'Mes.Market.IsUsing': 'Thị trường đang được sử dụng',
  'Mes.TargetCustomer.IsUsing': 'Khách hàng mục tiêu đang được sử dụng',
  'Mes.Organization.IsUsing': 'Tổ chức đang được sử dụng',
  'Mes.Contact.Update.Successfully': 'Cập nhật liên hệ thành công',
  'Mes.Role.StillExistUser': 'Không thể xóa vai trò đã được sử dụng',
  'Mes.Schedule.AttachPath.OverLength': 'Không thể thêm file quá 6mb hoặc 3 file cùng lúc',
  'Mes.Notification.Attach.OverLength': 'Không thể thêm file quá 6mb hoặc 3 file cùng lúc',
  'Mes.Fee.Attach.OverLength': 'Không thể thêm file quá 6mb hoặc 3 file cùng lúc',
  'Mes.Media.File.OverLength': 'Không thể thêm file quá 6mb hoặc 3 file cùng lúc',
  'Mes.Media.Files.OverLength': 'Không thể thêm file quá 6mb hoặc 3 file cùng lúc',
  'Mes.User.Id.InValid': 'Không thể xóa người dùng đang hoạt động',
  'Mes.Member.Duplicate': 'Trùng mã đăng nhập hoặc email',
  'Mes.OnlySupport.Excel': 'Chỉ hỗ trợ file excel',
  'Mes.Member.Import.Successfully': 'Thành công',
  'Mes.ClubRepresent.Import.Successfully': 'Thành công',
  'Mes.FieldRelation.AlreadyExist.Code': 'Mã nhóm con đã tồn tại',

  'Mes.LeaderInfo.EmailAddress.AlreadyExist': 'Email Lãnh đạo đã tồn tại',
  'Mes.User.Username.AlreadyExist': 'Tên đăng nhập đã tồn tại',
  'Mes.User.EmailAddress.AlreadyExist': 'Email đã tồn tại',
  'Mes.LeaderInfo.EmailAddress.Duplicate': 'Mail của đơn vị không được trùng với mail lãnh đạo',
  'Mes.FieldRelation.Code.Required': 'Mã nhóm con tồn tại',
  // added on 7/25/2023
  'Mes.Activity.Create.Successfully': 'Tạo hoạt động thành công',
  'Mes.Activity.Update.Successfully': 'Cập nhật hoạt động thành công',
  'Mes.Activity.Delete.Successfully': 'Xóa hoạt động thành công',
  'Mes.MeritBadge.Create.Successfully': 'Tạo chuyên hiệu thành công',
  'Mes.MeritBadge.Update.Successfully': 'Cập nhật chuyên hiệu thành công',
  'Mes.MeritBadge.Delete.Successfully': 'Xóa chuyên hiệu thành công',
  'Mes.Suggest.Approve.Successfully': 'Duyệt đề xuất khen thưởng thành công',
  'Mes.Suggest.Reject.Successfully': 'Từ chối đề xuất khen thưởng thành công',
  'Mes.Suggest.Delete.Successfully': 'Xóa đề xuất khen thưởng thành công',
  'Mes.Reward.Delete.Successfully': 'Xóa khen thưởng thành công',
  'Mes.Report.Create.Successfully': 'Tải báo cáo thành công',
  'Mes.Report.Delete.Successfully': 'Xóa báo cáo thành công',
  'Mes.User.NotAllowed': 'Tài khoản của bạn không được phép thực hiện thao tác này',
  'Mes.Activity.Title.Required': 'Tên hoạt động là bắt buộc',
  'Mes.Activity.Title.OverLength': 'Tên hoạt động vượt quá độ dài cho phép',
  'Mes.Activity.Content.Required': 'Nội dung hoạt động là bắt buộc',
  'Mes.Activity.Content.OverLength': 'Nội dung hoạt động vượt quá độ dài cho phép',
  'Mes.Activity.StartTime.Required': 'Thời gian bắt đầu là bắt buộc',
  'Mes.Activity.EndTime.Required': 'Thời gian kết thúc là bắt buộc',
  'Mes.Activity.EndTime.InValid': 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
  'Mes.Activity.NotFound': 'Hoạt động không tồn tại',
  'Mes.Activity.NotAllowed': 'Hoạt động không được phép cho phép',
  'Mes.MeritBadge.NotFound': 'Chuyên hiệu không tồn tại',
  'Mes.MeritBadge.Id.Required': 'Chuyên hiệu là bắt buộc',
  'Mes.MeritBadge.Id.Duplicate': 'Chuyên hiệu bị lặp lại',
  'Mes.MeritBadge.Code.Required': 'Mã chuyên hiệu là bắt buộc',
  'Mes.MeritBadge.Code.OverLength': 'Mã chuyên hiệu chỉ dưới 255 kí tự',
  'Mes.MeritBadge.Code.AlreadyExist': 'Mã chuyên hiệu đã tồn tại',
  'Mes.MeritBadge.Name.Required': 'Tên chuyên hiệu là bắt buộc',
  'Mes.MeritBadge.Name.OverLength': 'Tên chuyên hiệu chỉ dưới 255 kí tự',
  'Mes.MeritBadge.Description.OverLength': 'Mô tả chuyên hiệu chỉ dưới 255 kí tự',
  'Mes.Member.Grade.Required': 'Khối là bắt buộc',
  'Mes.Member.Grade.InValid': 'Khối không hợp lệ',
  'Mes.Member.MustFromClub': 'Đội viên phải thuộc câu lạc bộ',
  'Mes.Member.Id.Duplicate': 'Id của đội viên bị trùng lặp',
  'Mes.Activity.Grades.Required': 'Chọn khối',
  'Mes.Activity.Grades.Duplicate': 'Khối bị trùng lặp',
  'Mes.Activity.Score.Required': 'Điểm là bắt buộc',
  'Mes.Activity.Score.NotEnoughLength': 'Điểm phải lớn hơn 0',
  'Mes.Activity.NotStart': 'Hoạt động chưa bắt đầu',
  'Mes.Activity.HadStarted': 'Hoạt động đã bắt đầu',
  'Mes.Activity.HadConfirmed': 'Hoạt động đã được xác nhận',
  'Mes.Suggests.Status.MustWaiting': 'Hoạt động phải chưa được xác nhận',
  'Mes.Reward.NotFound': 'Khen thưởng không tồn tại',
  'Mes.CreateRewardSuggestRequest.MemberIds.Required': 'Nhập đội viên',
  'Mes.CreateRewardSuggestRequest.MemberIds.InValid': 'Id đội viên trùng lặp',
  'Mes.DeleteManyRewardRequest.Ids.Required': 'Id bắt buộc',
  'Mes.DeleteManyRewardRequest.Ids.InValid': 'Id đang trùng lặp',
  'Mes.CreateRewardSuggestRequest.ProposedReason.Required': 'Lý do đề xuất bắt buộc',
  'Mes.CreateRewardSuggestRequest.RejectReason.Required': 'Lý do là bắt buộc',
  'Mes.Report.NotFound': 'Báo cáo không tồn tại',
  'Mes.Report.Name.Required': 'Tên báo cáo là bắt buộc',
  'Mes.Report.Name.OverLength': 'Tên báo cáo chỉ dưới 255 kí tự',
  'Mes.Report.AttachFile.Required': 'File đính kèm bắt buộc',
  'Mes.Report.AttachFile.InValid': 'File đính kèm không hợp lệ',
  'Mes.Activity.OtherEmails.Duplicate': 'Email khác không được trùng lặp',
  'Mes.Activity.Attach.OverLength':
    'Đính kèm không được vượt quá 3 tệp và mỗi file không vượt quá 6mb',
  // update on 18/10/2023
  'Mes.Feedback.Id.Required': 'Vui lòng nhập id phản ánh',
  'Mes.Feedback.NotFound': 'Phản ánh không tồn tại',
  'Mes.Feedback.Tittle.Required': 'Vui lòng nhập tên phản ánh',
  'Mes.Feedback.Tittle.OverLength': 'Tên phản ánh quá dài',
  'Mes.Feedback.Content.Required': 'Vui lòng nhập nội dung phản ánh',
  'Mes.Feedback.Content.OverLength': 'Nội dung phản ánh quá dài',
  'Mes.Feedback.ContentAttachs.OverLength':
    'File đính kèm cho nội dung vượt quá dung lượng cho phép',
  'Mes.Feedback.ReplyAttachs.OverLength': 'File đính kèm cho trả lời vượt quá dung lượng cho phép',
  'Mes.Feedback.Create.Successfully': 'Tạo phản ánh thành công',
  'Mes.Feedback.Update.Successfully': 'Cập nhật phản ánh thành công',
  'Mes.Feedback.Delete.Successfully': 'Xóa phản ánh thành công',
  'Mes.Feedback.GetDetail.Successfully': 'Nhận chi tiết phản ánh thành công',
  'Mes.Feedback.Search.Successfully': 'Tìm kiếm phản ánh thành công',

  // update on 23/01/2024
  // Document
  'Mes.Document.NotFound': 'Thông tin không tồn tại',
  'Mes.Document.Tittle.Required': 'Vui lòng nhập tên thông tin',
  'Mes.Document.CoverImage.Required': 'Vui lòng nhập ảnh bìa thông tin',
  'Mes.Document.Tittle.OverLength': 'Tên thông tin quá dài',
  'Mes.Document.Content.Required': 'Vui lòng nhập nội dung thông tin',
  'Mes.Document.Content.OverLength': 'Nội dung thông tin quá dài',
  'Mes.Document.Description.OverLength': 'Mô tả thông tin quá dài',
  'Mes.Document.ClubIds.Duplicate': 'Câu lạc bộ không được trùng lặp',
  'Mes.Document.ClubIds.NotFound': 'Câu lạc bộ không tồn tại',
  'Mes.Document.MemberTypes.Required': 'Vui lòng nhập loại hội viên cho thông tin',
  'Mes.Document.MemberTypes.Duplicate': 'Loại hội viên không được trùng lặp',
  'Mes.Document.MemberTypes.InValid': 'Loại hội viên không hợp lệ',
  'Mes.Document.Ids.Required': 'Yêu cầu id thông tin',
  'Mes.Document.Create.Successfully': 'Tạo thông tin thành công',
  'Mes.Document.Create.Failed': 'Tạo thông tin thất bại',
  'Mes.Document.Update.Successfully': 'Cập nhật thông tin thành công',
  'Mes.Document.Update.Failed': 'Cập nhật thông tin thất bại',
  'Mes.Document.Delete.Successfully': 'Xóa thông tin thành công',
  'Mes.Document.Delete.Failed': 'Xóa thông tin thất bại',
  'Mes.Document.GetDetail.Successfully': 'Nhận chi tiết thông tin thành công',
  'Mes.Document.Search.Successfully': 'Tìm kiếm thông tin thành công',

  // CoverImage
  'Mes.CoverImage.Description.OverLength': 'Mô tả ảnh bìa quá dài',
  'Mes.CoverImage.File.InValid': 'Mô tả ảnh bìa quá dài',
  'Mes.Upload.Create.Successfully': 'Thêm ảnh thành công',

  // update on 26/01
  'Mes.AccumulationLevel.NotAllowed': 'Không được phép cập nhập mức lũy kế',
  'Mes.AccumulationLevel.NotEnoughLength.PercentageValue':
    'Phần trăm mức lũy kế phải lớn hơn hoặc bằng 0',
  'Mes.AccumulationLevel.OverLength.PercentageValue':
    'Phần trăm mức lũy kế không được vượt qua 100',
  'Mes.AccumulationLevel.GetDetail.Successfully': 'Lấy chi tiết mức lũy kế thành công',
  'Mes.AccumulationLevel.Update.Successfully': 'Cập nhật mức lũy kế thành công',
  'Mes.AccumulationRank.Search.Successfully': 'Tìm kiếm hạng thành viên thành công',
  'Mes.AppUser.Username.AlreadyExist': 'Mã đăng nhập đã tồn tại',
  'Mes.Member.EmailAddress.AlreadyExist': 'Địa chỉ email đã tồn tại',
  'Mes.Files.Misalignment': 'File không hợp lệ',

  // update on 05/07
  'Mes.NetRevenue.ChangeStatus.Successfully': 'Duyệt đóng thành công',
  'Mes.Feedback.Reply.Successfully': 'Gửi phản hồi thành công',

  'Mes.Instruction.Update.Successfully': 'Cập nhật Tài liệu HDSD thành công',
};
