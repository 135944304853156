export const logo = require('./logo.png');
export const error = require('./error.png');
export const imgAvatar = require('./avatar.jpg');
export { default as rightImgLogin } from './right-login.png';

export { default as vietnam } from './vietnam.png';
export { default as english } from './english.png';
export { default as sponsors_icon } from './Sponsors_icon.png';
export { default as fees_icon } from './Fees_icon.png';
export { default as Pdf_icon } from './pdf_icon.png';
export { default as Word_icon } from './word_icon.png';
export { default as Audio_icon } from './audio_icon.png';
export { default as Txt_icon } from './txt_icon.png';
export { default as Rar_icon } from './rar_icon.png';
export { default as Zip_icon } from './zip_icon.png';
export { default as Unknown_icon } from './unknown_icon.png';
export { default as LogoutIcon } from './LogoutIcon.png';
export { default as discount } from './discount.png';
export { default as ISC_word } from './ISC_word.jpg';
export { default as ISC_pdf } from './ISC_pdf.jpg';
