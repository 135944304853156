import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerDocumentTutorial: IRouter = {
  path: '/document-tutorial',
  loader: React.lazy(() => import('./index')),
  exact: true,
  icon: <Icon.FileText />,
  name: 'documentTutorial.name', //translate here for breadcrumb and sidebar
  widthFull: true,
  permissionCode: PermissionCode.INSTRUCTIONS_UPDATE,
};
